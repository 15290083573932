function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function showPopup() {
    const popup = document.getElementById('home-popup-block');
    const overlay = document.getElementById('popup-overlay');
    if (popup && overlay) {
        popup.style.display = 'flex';
        overlay.style.display = 'block';
    }
}

function hidePopup() {
    const popup = document.getElementById('home-popup-block');
    const overlay = document.getElementById('popup-overlay');
    if (popup && overlay) {
        popup.style.display = 'none';
        overlay.style.display = 'none';
    }
    setCookie('popupDismissed', 'true', 3650);
}

function initPopup() {
    if (getCookie('popupDismissed') !== 'true') {
        setTimeout(function() {
            showPopup();
        }, 5000);
    }
}

initPopup();

document.addEventListener('DOMContentLoaded', function() {
    const closeButton = document.getElementById('close-popup');
    if (closeButton) {
        closeButton.addEventListener('click', hidePopup);
    }
});